
<template>
  <div>
    <a-modal title="查看商品" v-model="visible" width="70%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{xs:7}" :wrapper-col="{xs:17}">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="商品名称">
                <a-input v-model="searchData.goodsName" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table :data-source="tableData" :loading="tableLoading" :rowKey="record => record.id" :pagination="page" bordered @change="changeTable">
            <a-table-column title="商品名称" data-index="goodsName" ></a-table-column>
            <a-table-column title="商品图片" data-index="goodsPicture" align="center">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="商品规格" data-index="goodsStr" align="center"></a-table-column>
            <a-table-column title="品牌名称" data-index="brandName" align="center"></a-table-column>
            <a-table-column title="最终价格" data-index="finalPrice" align="center">
              <template slot-scope="text">{{text.toFixed(2)}}</template>
            </a-table-column>
            <a-table-column title="商品数量" data-index="number" align="center" :width="120">
            </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        tableLoading: false,
        btnsLoading: false,
        tableData: [],
        handle: '',
        searchData: {},
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
        },
        selectedRowKeys: [],
        selectedRows: [],
      }
    },

    created() {},

    methods: {
      // 打开弹框触发
      isShow(id) {
        this.visible = true
        this.orderId = id
        this.getList()
      },
      // 获取数据源
      getList(num) {
        this.tableLoading = true
        if(num == 1){
            this.searchData = {}
            this.page.pageSize = 10
            this.page.pageNumber = 1
        }
        let obj = {
          orderId:this.orderId,
          pageSize:this.page.pageSize,
          pageNumber:this.page.pageNumber,
          ...this.searchData
        }
        this.axios.get('/api/dealer/customer/dealerCustomerOrder/getProDetailList',{params:obj} ).then(res => {
          if(res.code === 200) {
            this.tableData = res.body.records || []
            this.page.total = res.body.total
            this.tableLoading = false
          }
        })
      },
      // 关闭弹框
      onCancel() {
        this.visible = false
      },

      changeTable(pagination) {
        this.page = pagination
        this.getList()
      },

      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },
    
      // 获取政策列表
      onSubmit() {
        this.$emit('get-product-list')
        this.visible = false
        this.selectedRowKeys = []
      },
    }
  }
</script>

<style scoped>
  .steps-action {
    margin-top: 20px;
    text-align: right;
  }
  .steps-content {
    margin-top: 60px;
  }
  .footer-btns {
    text-align: right;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
    margin-top: 20px;
  }
</style>
